<template>
  <v-container>
    <v-row>
      <v-col class="primary d-none d-lg-flex flex-column text-center" cols="7">
        <h3 class="white--text mt-16">SMARTMED-TEST</h3>
        <h1 class="white--text">
          Verbreiten Sie die Idee, <br />
          nicht das Virus!
        </h1>
        <v-img src="../assets/CORONA_TEST_POINT_BG_IMAGE_2000PX_RGB.png" height="633" contain> </v-img>
      </v-col>
      <v-col class="d-flex justify-center text-center align-center">
        <v-form ref="form" v-model="valid" lazy-validation>
          <h2 class="headline font-weight-bold mb-1">
            Bitte geben Sie ein <br />
            neues Passwort ein.
          </h2>
          <div v-if="error != ''" class="red--text">{{ error }}</div>
          <v-text-field
            v-model="newPassword.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="Passwort"
            hint="Mindestens 8 Zeichen"
            :rules="[() => newPassword.password.length >= 8 || 'Mindestens 8 Zeichen']"
            counter
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            class="mt-5"
            required
          ></v-text-field>
          <v-text-field
            v-model="newPassword.password2"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[
              () => newPassword.password2.length >= 8 || 'Mindestens 8 Zeichen',
              () => newPassword.password2 == newPassword.password || 'Passwörter müssen übereinstimmen',
            ]"
            hint="Mindestens 8 Zeichen"
            counter
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            label="Passwort bestätigen"
            required
          ></v-text-field>
          <v-btn :disabled="!valid" block x-large color="primary light" class="mt-5" @click="submit">
            Passwort zurücksetzen
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    showPassword: false,
    snackbar: false,
    snackbarText: "",
    error: "",
    newPassword: {
      password: "",
      password2: "",
    },
  }),

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$http({
          method: "post",
          url: "/entrance/update-password-and-login",
          data: {
            password: this.newPassword.password,
            token: this.$route.params.token,
          },
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch((err) => {
            if (err.response.status == 498) {
              this.error = "Passwort Reset Token abgelaufen.";
              this.snackbarText = "Passwort Reset Token abgelaufen";
            } else {
              this.snackbarText = "Fehler bei Abfrage";
            }
            this.snackbar = true;
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>
